body {
	font-size: 14px;
	/* height: 100%;
	overflow: hidden; */
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
/* Custom Style */
.list {
	text-align: left;
	/* max-width: 750px; */
	margin: auto;
}

.submit-form {
	max-width: 300px;
	margin: auto;
}

.edit-form {
	max-width: 400px;
	margin: auto;
}
/* 
.project-box{
  padding:20px 10px;
  background-color: #f5fcff;
  border:1px solid #ccc;
  border-radius:5px;
  text-transform: uppercase;
  color:#1167b1;
  box-shadow:1px 1px 1px #eee 
} */
.list-heading {
	padding: 6px;
	border-bottom: 1px solid #dedede;
	text-transform: uppercase;
	font-weight: bold;
}
.project-box {
	padding: 10px;
	border-bottom: 1px solid #dedede;
}
.project-box svg {
	margin-right: 5px;
	cursor: pointer;
}

.product-list-table svg {
	margin-right: 5px;
	cursor: pointer;
}

.project-list a {
	text-decoration: none;
	color: #5f84a2;
}
/* .folder-box{
  padding:20px 10px;
  background-color: #f5fcff;
  border:1px solid #ccc;
  border-radius:5px;
  text-transform: uppercase;
  color:#1167b1;
  box-shadow:1px 1px 1px #eee;
  text-decoration:none
} */
.folder-box {
	padding: 10px;
	border-bottom: 1px solid #dedede;
}
.profolder-box svg {
	margin-right: 5px;
}

.subfolders a {
	text-decoration: none;
}
.h4,
h4 {
	font-size: 1.3rem !important;
}
/* .action_buttons a{
  background-color:green;
  text-decoration: none;
  margin-right:20px
}
.action_buttons a:hover{
  color:#fff
} */
/* .action-buttons{
  position: absolute;
  right:15px;
  margin-top:5px;
} */

.action-buttons button {
	font-size: 12px;
}

.action-buttons svg {
	margin-right: 0px;
	cursor: pointer;
}

.action-button-grid svg {
	margin-right: 10px;
	cursor: pointer;
}
.m-0 {
	margin: 0px;
}
.m-l-10 {
	margin-left: 10px;
}
.m-r-10 {
	margin-right: 10px;
}
.m-t-10 {
	margin-top: 10px;
}
.m-b-10 {
	margin-bottom: 10px;
}
.p-0 {
	padding: 0px;
}
.m-r-5 {
	margin-right: 5px;
}
.m-t-0 {
	margin-top: 0px;
}
.m-l-5 {
	margin-left: 5px;
}
.m-r-5 {
	margin-right: 5px;
}
.p-10 {
	padding: 10px;
}
.p-20 {
	padding: 20px;
}

.border-1 {
	border: 1px solid #ddd;
}
.menu-right {
	position: absolute;
	right: 3px;
}
/* login css */

label {
	display: block;
	margin-top: 10px;
}

.card-container.card {
	max-width: 350px !important;
	padding: 40px 40px;
}

.card {
	background-color: #f7f7f7;
	padding: 20px 25px 30px;
	margin: 0 auto 25px;
	margin-top: 50px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
	width: 96px;
	height: 96px;
	margin: 0 auto 10px;
	display: block;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.position-absolute {
	position: absolute;
}
.position-relative {
	position: relative;
}
.p-l-5 {
	padding-left: 5px;
}
.p-l-5 {
	padding-left: 5px;
}
.p-l-10 {
	padding-left: 10px;
}
.p-r-10 {
	padding-right: 10px;
}
.text-black {
	color: #000 !important;
}
.m-l-0 {
	margin-left: 0px;
}
.m-r-0 {
	margin-right: 0px;
}
.p-r-20 {
	padding-right: 20px;
}
.text-center {
	text-align: center;
}
.restricted {
	position: absolute;
	right: 25px;
	bottom: 5px;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.clear-both {
	clear: both;
}
.border-btn-bottom {
	border-bottom: 1px solid #dedede;
	padding-bottom: 8px;
	margin-bottom: 15px;
}
.td-bg {
	background: #64abd4;
	color: #fff;
}
.btn-primary {
	background-color: #64abd4 !important;
	border-color: #64abd4 !important;
}
/* .bg-sky-blue{
  background-color:#1261A0;
  color:#fff
} */
/* .bg-image{
  /* background-image: url('../public/dms.jpg');
  background-position: center;
  /* background-color:#cfeefa 
}  */
/* Custom Style */
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/*Css for popup modal*/
.react-confirm-alert-body {
	width: min-content;
	min-width: 500px;
}
.sizeError {
	color: red;
}
/*Ends*/

/* Css for pagination */
.pagination {
	margin: 15px auto;
	display: flex;
	list-style: none;
	outline: none;
	float: right;
}
.pagination > .active > a {
	background-color: #47ccde;
	border-color: #47ccde;
	color: #fff;
}
.pagination > li > a {
	border: 1px solid #47ccde;
	padding: 5px 10px;
	outline: none;
	cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #47ccde;
	border-color: #47ccde;
	outline: none;
}
.pagination > li > a,
.pagination > li > span {
	color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-radius: unset;
}
/* Ends */

/*CSS for Image view starts */
.img-view {
	max-width: 900px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.image-container {
	overflow: hidden;
	position: relative;
}
.image-container-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}
/*Css for Image view ends */

/*Navbar Css starts*/
.navbar.navbar-expand-lg .container {
	margin-left: inherit;
}
.navbar-brand a.nav-link {
	color: rgba(255, 255, 255, 0.5);
	padding: 0;
}

/*Ends*/

/*Product List Table Starts*/
.threedots:after {
	content: "\2807";
	font-size: 24px;
}
.hide-desktop {
	display: none;
}
.hide-mobile {
	display: block;
}
.product-list-table td:first-child {
	width: 30%;
}
.product-list-table thead tr th {
	border-right: none;
	border-left: none;
}
.product-list-table tbody tr td {
	border-right: none;
	border-left: none;
}
.table-striped tbody tr:nth-of-type(odd) {
	background: none;
}
td.hide-desktop + td {
	width: 30%;
}
.product-list-table tr th {
	border: none;
}
.product-list-table td .restricted {
	position: unset !important;
	float: right;
}

/*Ends*/

table tr a {
	color: #000;
}
table tr a:hover {
	color: #000;
	text-decoration: none;
}
.cursor_pointer {
	cursor: pointer;
}

h4 .breadcrump-title {
	border-radius: 5px;
	padding: 5px;
}

h4 .breadcrump-title:hover {
	cursor: pointer;
	background: #ececec;
}
h4.breadcrump-header:last-of-type .breadcrump-title:hover {
	background: none !important;
	cursor: unset !important;
}
.breadcrump-manual {
	flex-wrap: wrap;
}

.no-hover .breadcrump-title:hover {
	cursor: unset;
	background: none;
}

/*Side Drawer Css Starts*/
.SlideModal {
	width: 25%;
}
.slider-drawer-content {
	padding-left: 15px;
	padding-top: 10px;
}
/*Ends*/

.right-folder-details {
	text-align: right;
	position: absolute;
	right: 0;
}

/*Media query for mobile */
@media only screen and (max-width: 768px) {
	.menu-right {
		position: static !important;
	}
	.react-confirm-alert-body {
		width: auto;
		min-width: auto;
	}
	.product-list-table .hide-mobile {
		display: none;
	}
	.product-list-table .hide-desktop {
		display: block;
	}
	.product-list-table td:first-child {
		width: 100%;
	}
	.product-list-table th.hide-desktop,
	.product-list-table td.hide-desktop {
		border-bottom: none !important;
	}
	.table-bordered td,
	.table-bordered th {
		border-bottom: 1px solid #ededed !important;
	}
	.product-list-table tr th {
		border-bottom: 1px solid #ededed !important;
	}
	.right-folder-details {
		text-align: center;
		position: static;
	}
}
/*Ends*/
